import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  RdsDialogService,
  RdsProgressSpinnerModule,
  RdsSearchModule,
} from '@rds/angular-components';

import { RoomsModel } from '@bookly/shared';

import { RoomsListTableComponent } from '../../components/rooms-list-table/rooms-list-table.component';
import { RoomsService } from '../../services/rooms.service';
import { RoomDetailsComponent } from '../room-details/room-details.component';

@Component({
  selector: 'bad-rooms-list',
  imports: [RoomsListTableComponent, RdsSearchModule, RdsProgressSpinnerModule],
  templateUrl: './rooms-list.component.html',
  styleUrl: './rooms-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomsListComponent implements OnInit {
  readonly #roomsService = inject(RoomsService);
  readonly #dialogService = inject(RdsDialogService);
  protected readonly rooms = this.#roomsService.rooms;
  protected readonly isRoomListLoading = this.#roomsService.isRoomListLoading;

  public ngOnInit() {
    this.#roomsService.getRooms().subscribe();
  }

  protected openRoomDetails(data: RoomsModel) {
    this.#dialogService.open(RoomDetailsComponent, {
      disableClose: true,
      size: 'm',
      maxHeight: '100%',
      data,
    });
  }
}
