import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CalendarOverviewComponent } from '../../components/calendar-overview/calendar-overview.component';

@Component({
  selector: 'bad-rooms-calendar-overview',
  imports: [CalendarOverviewComponent],
  templateUrl: './rooms-calendar-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomsCalendarOverviewComponent {}
