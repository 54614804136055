import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  computed,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  RDS_DIALOG_DATA,
  RdsButtonModule,
  RdsDialogModule,
  RdsDialogRef,
  RdsDialogService,
  RdsDividerModule,
  RdsFormFieldModule,
  RdsIconComponent,
  RdsProgressSpinnerModule,
  RdsRadioButtonModule,
} from '@rds/angular-components';
// eslint-disable-next-line import/no-unresolved
import { RdsRadioChangedEvent } from '@rds/angular-components/lib/radio-button/radio-button-changed-event';
import { filter, of, switchMap, tap } from 'rxjs';

import {
  ManageSpaceConfigurationModel,
  RoomSettingModel,
  RoomSettingsBlockingTimeModel,
  RoomSettingsDurationRangeModel,
  RoomsModel,
} from '@bookly/shared';

import { LayoutTableComponent } from '../../components/layout-table/layout-table.component';
import { LayoutUploadComponent } from '../../components/layout-upload/layout-upload.component';
import { RoomsService } from '../../services/rooms.service';

@Component({
  selector: 'bad-room-details',
  imports: [
    RdsDialogModule,
    RdsButtonModule,
    RdsIconComponent,
    RdsDividerModule,
    RdsFormFieldModule,
    RdsRadioButtonModule,
    ReactiveFormsModule,
    RdsProgressSpinnerModule,
    LayoutTableComponent,
  ],
  templateUrl: './room-details.component.html',
  styleUrl: './room-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomDetailsComponent implements OnInit {
  readonly #roomService = inject(RoomsService);
  readonly #dialogService = inject(RdsDialogService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #fb = inject(FormBuilder);
  readonly #rdsDialogRef =
    inject<RdsDialogRef<RoomDetailsComponent>>(RdsDialogRef);
  protected readonly roomData = inject(RDS_DIALOG_DATA) as RoomsModel;
  protected readonly roomLayouts = this.#roomService.roomLayouts;
  protected readonly isLoading = computed(
    () =>
      this.#roomService.isRoomDetailsLoading() ||
      this.#roomService.isRoomLayoutsLoading()
  );
  protected roomSettings: RoomSettingModel | undefined;
  protected readonly roomConfigForm = this.#fb.group({
    description: this.#fb.nonNullable.control<string>('', [
      Validators.maxLength(1024),
    ]),
    active: this.#fb.nonNullable.control<boolean>(true),
    automaticApproval: this.#fb.nonNullable.control<boolean>(false),
    defaultLayoutId: this.#fb.nonNullable.control<string>(''),
    setBlockingTime: this.#fb.nonNullable.control<boolean>(false),
    setMeetingDurationRangeTime: this.#fb.nonNullable.control<boolean>(false),
    blockingTimes: this.#fb.nonNullable.array<
      FormGroup<{
        from: FormControl<string>;
        to: FormControl<string>;
        uuid: FormControl<string | null>;
      }>
    >([]),
    durationRange: this.#fb.nonNullable.group({
      min: this.#fb.group({
        days: this.#fb.nonNullable.control<number>(0, [Validators.min(0)]),
        hours: this.#fb.nonNullable.control<number>(0, [Validators.min(0)]),
        minutes: this.#fb.nonNullable.control<number>(0, [Validators.min(0)]),
      }),
      max: this.#fb.nonNullable.group({
        days: this.#fb.nonNullable.control<number>(0, [Validators.min(0)]),
        hours: this.#fb.nonNullable.control<number>(0, [Validators.min(0)]),
        minutes: this.#fb.nonNullable.control<number>(0, [Validators.min(0)]),
      }),
    }),
  });

  public ngOnInit() {
    this.#roomService
      .getRoomSettings(this.roomData.roomDexId)
      .subscribe(roomSettings => {
        this.roomSettings = roomSettings;
        if (roomSettings.manageSpaceConfiguration) {
          this.#initRoomConfiguration(roomSettings.manageSpaceConfiguration);
        }
      });
    this.#roomService.getRoomLayouts(this.roomData.roomDexId).subscribe();
  }

  protected openLayoutUploadDialog() {
    const layoutDialog = this.#dialogService.open(LayoutUploadComponent, {
      disableClose: true,
      size: 's',
      maxHeight: '100%',
      data: this.roomData,
    });

    layoutDialog
      .afterClosed()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter(Boolean),
        switchMap(() =>
          this.#roomService.getRoomLayouts(this.roomData.roomDexId)
        ),
        switchMap(roomLayouts => {
          if (roomLayouts.length === 1) {
            {
              return this.#roomService
                .updateRoomSettings(this.roomData.roomDexId, {
                  ...this.roomSettings?.manageSpaceConfiguration,
                  roomDexId: this.roomData.roomDexId,
                  defaultLayoutId: roomLayouts[0].layoutDexUUID,
                })
                .pipe(
                  tap(() => {
                    if (this.roomSettings) {
                      this.roomSettings = {
                        ...this.roomSettings,
                        dexId: this.roomData.roomDexId,
                        manageSpaceConfiguration: {
                          ...this.roomSettings?.manageSpaceConfiguration,
                          roomDexId: this.roomData.roomDexId,
                          defaultLayoutId: roomLayouts[0].layoutDexUUID,
                        },
                      };
                      this.roomConfigForm.controls.defaultLayoutId.patchValue(
                        roomLayouts[0].layoutDexUUID
                      );
                    }
                  })
                );
            }
          }
          return of(roomLayouts);
        })
      )
      .subscribe();
  }

  protected updateRoomConfig() {
    this.roomConfigForm.controls.blockingTimes.updateValueAndValidity();

    const roomSettings: ManageSpaceConfigurationModel = {
      active: this.roomConfigForm.controls.active.value,
      blockingTimes: [],
      defaultLayoutId: this.roomConfigForm.controls.defaultLayoutId.value,
      description: this.roomConfigForm.controls.description.value,
      durationRange: {
        min: {} as RoomSettingsDurationRangeModel,
        max: {} as RoomSettingsDurationRangeModel,
      },
      automaticApproval: this.roomConfigForm.controls.automaticApproval.value,
      roomDexId: this.roomData.roomDexId,
    };

    if (this.roomConfigForm.controls.blockingTimes.length > 0) {
      roomSettings.blockingTimes = this.roomConfigForm.controls.blockingTimes
        .value as RoomSettingsBlockingTimeModel[];
    }

    if (this.roomConfigForm.value.setMeetingDurationRangeTime) {
      roomSettings.durationRange = {
        min: {
          ...(this.roomConfigForm.controls.durationRange.controls.min
            .value as RoomSettingsDurationRangeModel),
        },
        max: {
          ...(this.roomConfigForm.controls.durationRange.controls.max
            .value as RoomSettingsDurationRangeModel),
        },
      };
    }

    this.#roomService
      .updateRoomSettings(this.roomData.roomDexId, roomSettings)
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.#rdsDialogRef.close();
      });
  }

  protected updateDefaultLayout(layoutId: string) {
    this.roomConfigForm.controls.defaultLayoutId.patchValue(layoutId);
  }

  protected removeLayout(layoutId: string) {
    this.#roomService
      .deleteLayout(layoutId)
      .pipe(
        switchMap(() =>
          this.#roomService.getRoomLayouts(this.roomData.roomDexId)
        )
      )
      .subscribe();
  }

  #initRoomConfiguration(roomConfiguration: ManageSpaceConfigurationModel) {
    this.roomConfigForm.patchValue({
      description: roomConfiguration.description,
      active: roomConfiguration.active,
      automaticApproval: roomConfiguration.automaticApproval,
      defaultLayoutId: roomConfiguration.defaultLayoutId,
    });

    if (
      roomConfiguration.durationRange &&
      'days' &&
      'hours' &&
      'minutes' in roomConfiguration.durationRange.min
    ) {
      this.roomConfigForm.controls.setMeetingDurationRangeTime.patchValue(true);
      this.roomConfigForm.controls.durationRange.controls.min.patchValue({
        days: roomConfiguration.durationRange.min.days,
        hours: roomConfiguration.durationRange.min.hours,
        minutes: roomConfiguration.durationRange.min.minutes,
      });
    }

    if (
      roomConfiguration.durationRange &&
      'days' &&
      'hours' &&
      'minutes' in roomConfiguration.durationRange.max
    ) {
      this.roomConfigForm.controls.setMeetingDurationRangeTime.patchValue(true);
      this.roomConfigForm.controls.durationRange.controls.max.patchValue({
        days: roomConfiguration.durationRange.max.days,
        hours: roomConfiguration.durationRange.max.hours,
        minutes: roomConfiguration.durationRange.max.minutes,
      });
    }

    if (
      roomConfiguration.blockingTimes &&
      roomConfiguration.blockingTimes.length > 0
    ) {
      this.roomConfigForm.controls.setBlockingTime.patchValue(true);
      roomConfiguration.blockingTimes.forEach(blockingTime => {
        const formGroup = this.#fb.group({
          from: this.#fb.nonNullable.control(blockingTime.from, [
            Validators.required,
          ]),
          to: this.#fb.nonNullable.control(
            blockingTime.to,
            Validators.required
          ),
          uuid: this.#fb.control<string | null>(blockingTime.uuid),
        });

        this.roomConfigForm.controls.blockingTimes.push(formGroup);
      });
    }
  }

  protected addBlockingTime() {
    const formGroup = this.#fb.group({
      from: this.#fb.nonNullable.control('', [Validators.required]),
      to: this.#fb.nonNullable.control('', Validators.required),
      uuid: this.#fb.control<string | null>(null),
    });

    this.roomConfigForm.controls.blockingTimes.push(formGroup);
  }

  protected toggleRoomBlockingTime(
    radioButtonData: RdsRadioChangedEvent<boolean>
  ) {
    if (
      radioButtonData.value &&
      this.roomSettings?.manageSpaceConfiguration?.blockingTimes &&
      this.roomSettings.manageSpaceConfiguration.blockingTimes.length > 0
    ) {
      this.roomSettings.manageSpaceConfiguration.blockingTimes.forEach(
        blockingTime => {
          const formGroup = this.#fb.group({
            from: this.#fb.nonNullable.control(blockingTime.from.slice(0, 5), [
              Validators.required,
            ]),
            to: this.#fb.nonNullable.control(
              blockingTime.to.slice(0, 5),
              Validators.required
            ),
            uuid: this.#fb.control<string | null>(blockingTime.uuid),
          });

          this.roomConfigForm.controls.blockingTimes.push(formGroup);
        }
      );

      return;
    }

    if (
      radioButtonData.value &&
      this.roomConfigForm.controls.blockingTimes.length === 0
    ) {
      this.addBlockingTime();

      return;
    }

    if (!radioButtonData.value) {
      this.roomConfigForm.controls.blockingTimes.clear();
    }
  }
}
